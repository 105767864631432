<script>
import AxiosService from "../axiosServices/AxiosService";
export default {
  name: "Reminder",
  created() {
    this.currentCompany = this.currentCompanyFromStore;
  },
  data() {
    return {
      loading: {
        getData: false,
        saveItem: false,
      },

      currentCompany: null,

      reminderList: [],

      fields: [
        { key: "actions", label: "Actions" },
        { key: "oggetto_mail", label: "Subject" },
        { key: "frequency", label: "Frequency" },
      ],

      frequencies: [
        { value: "mensile", label: "Monthly" },
        { value: "settimanale", label: "Weekly" },
        { value: "giornaliero", label: "Daily" },
      ],

      monthDays: [
        { value: 1, text: "1" },
        { value: 2, text: "2" },
        { value: 3, text: "3" },
        { value: 4, text: "4" },
        { value: 5, text: "5" },
        { value: 6, text: "6" },
        { value: 7, text: "7" },
        { value: 8, text: "8" },
        { value: 9, text: "9" },
        { value: 10, text: "10" },
        { value: 11, text: "11" },
        { value: 12, text: "12" },
        { value: 13, text: "13" },
        { value: 14, text: "14" },
        { value: 15, text: "15" },
        { value: 16, text: "16" },
        { value: 17, text: "17" },
        { value: 18, text: "18" },
        { value: 19, text: "19" },
        { value: 20, text: "20" },
        { value: 21, text: "21" },
        { value: 22, text: "22" },
        { value: 23, text: "23" },
        { value: 24, text: "24" },
        { value: 25, text: "25" },
        { value: 26, text: "26" },
        { value: 27, text: "27" },
        { value: 28, text: "28" },
        { value: 29, text: "29" },
        { value: 30, text: "30" },
        { value: 31, text: "31" },
      ],

      weekDays: [
        { value: 0, text: "Monday" },
        { value: 1, text: "Tuesday" },
        { value: 2, text: "Wednesday" },
        { value: 3, text: "Thursday" },
        { value: 4, text: "Friday" },
        { value: 5, text: "Saturday" },
        { value: 6, text: "Sunday" },
      ],

      tempItem: {
        oggetto_mail: "",
        corpo_mail: "",
        destinatari: [],

        id_company: null,

        mensile: false,
        settimanale: false,
        giornaliero: false,
        giorno: null,
        ora: null,

        id: null,
      },
    };
  },
  computed: {
    currentCompanyFromStore() {
      return this.$store.getters.currentCompany;
    },
  },
  watch: {
    currentCompanyFromStore() {
      this.currentCompany = this.currentCompanyFromStore;
      this.getData();
    },
  },
  methods: {
    selectFrequency(value) {
      console.log("selectFrequency", value);
      this.frequencies.forEach((frequency) => {
        this.$set(
          this.tempItem,
          frequency.value,
          frequency.value === value.value ? true : false
        );
      });
    },
    async getData() {
      const service = new AxiosService(
        "Reminder/GetByCompany/" + this.currentCompany
      );

      this.loading.getData = true;

      try {
        this.reminderList = await service.read();
      } catch (e) {
        console.error(e);
      } finally {
        this.loading.getData = false;
      }
    },
    async saveItem() {
      this.tempItem.id_company = this.currentCompany;

      const method = this.tempItem.id ? "Put" : "Post";
      const service = new AxiosService("Reminder/" + method);

      this.loading.saveItem = true;

      try {
        this.tempItem.id
          ? await service.update(this.tempItem)
          : await service.create(this.tempItem);
      } catch (e) {
        console.error(e);
      } finally {
        this.loading.saveItem = false;
      }
    },
  },
};
</script>

<template>
  <div class="content-wrapper">
    <div class=" w-100 flex justify-content-end">
      <b-button
        variant="primary"
        v-b-toggle.sidebar-detail
        @click="tempItem = {}"
        >New Reminder
      </b-button>
    </div>

    <b-table
      :items="reminderList"
      :fields="fields"
      :busy="loading.getData"
      responsive
      hover
    >
      <template #table-busy>
        <div class="text-center py-2 my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>

      <template #cell(actions)="row">
        <b-dropdown
          size="sm"
          variant="link"
          toggle-class="text-decoration-none"
          no-caret
        >
          <template #button-content>
            <b-icon-gear-fill></b-icon-gear-fill>
          </template>
          <b-dropdown-item
            href="#"
            v-b-toggle.sidebar-detail
            @click="tempItem = JSON.parse(JSON.stringify(row.item))"
            >Edit</b-dropdown-item
          >
          <b-dropdown-item href="#" @click="showDeleteModal = true"
            >Delete</b-dropdown-item
          >
        </b-dropdown>
      </template>

      <template #cell(oggetto_mail)="row">
        <b-form-input v-model="row.item.oggetto_mail"></b-form-input>
      </template>

      <template #cell(frequency)="row">
        <span v-if="row.item.mensile">
          Monthly
        </span>
        <span v-else-if="row.item.settimanale">
          Weekly
        </span>
        <span v-else-if="row.item.giornaliero">
          Daily
        </span>
      </template>
    </b-table>

    <b-sidebar id="sidebar-detail" class="right-sidebar" right shadow lazy>
      <div class="px-3 pt-4 mt-4">
        <b-form-group
          label="Frequency"
          class="w-100 flex justify-content-start align-items-center"
        >
          <div style="display: flex; justify-content: space-between; ">
            <b-card
              v-for="(frequency, index) in frequencies"
              :key="index"
              :style="{
                backgroundColor:
                  tempItem[frequency.value] === true
                    ? 'var(--whappy-primary)'
                    : '',
                color: tempItem[frequency.value] === true ? '#fff' : '',
              }"
              @click="selectFrequency(frequency)"
              class="mr-4 cursor-pointer"
            >
              <b-icon
                :icon="
                  index == 0
                    ? 'calendar3-event-fill'
                    : index == 1
                    ? 'calendar3-week'
                    : 'calendar3'
                "
                class="mr-2"
              ></b-icon>
              {{ frequency.label }}
            </b-card>
          </div>
        </b-form-group>

        <b-row>
          <b-col cols="12" md="6" v-if="tempItem.giornaliero == false">
            <b-form-group
              v-if="tempItem.mensile == true"
              label="Day"
              class="w-100 "
            >
              <b-form-select
                v-model="tempItem.giorno"
                :options="monthDays"
              ></b-form-select>
            </b-form-group>

            <b-form-group
              v-if="tempItem.settimanale == true"
              label="Day"
              class="w-100 "
            >
              <b-form-select
                v-model="tempItem.giorno"
                :options="weekDays"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6"
            ><b-form-group
              label="Time"
              class="w-100 flex justify-content-start align-items-center"
            >
              <b-form-timepicker
                v-model="tempItem.orario"
                locale="en"
              ></b-form-timepicker> </b-form-group
          ></b-col>
        </b-row>

        <b-form-group label="Subject">
          <b-form-input v-model="tempItem.oggetto_mail"></b-form-input>
        </b-form-group>

        <b-form-group label="Message">
          <b-form-textarea
            id="textarea"
            v-model="tempItem.corpo_mail"
            placeholder="Enter something..."
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </b-form-group>
      </div>
      <div class="d-flex justify-content-between align-items-center px-3 py-2">
        <b-button variant="primary" @click="saveItem">Save</b-button>
      </div>
    </b-sidebar>
  </div>
</template>

<template>
  <div class="content-wrapper">
    <h1 class="mb-5">Update Kpis</h1>
    <!-- <b-row>
      <b-col cols="4">
        <b-form-group>
          <b-form-select
            @change="getUploadHistory"
            v-model="currentCompany"
            :options="companiesList"
          ></b-form-select>
        </b-form-group>
      </b-col>
    </b-row> -->
    <h3 class="pt-4">Kpis Update Tools</h3>

    <b-card class="mb-4">
      <h4
        @click="updateFromSpreadsheet_show = !updateFromSpreadsheet_show"
        v-b-toggle.collapse-updateFromSpreadsheet
      >
        Update from spreadsheet (.xlsx)
        <b-icon-chevron-down
          v-if="updateFromSpreadsheet_show"
        ></b-icon-chevron-down>
        <b-icon-chevron-up v-else></b-icon-chevron-up>
      </h4>
      <b-collapse id="collapse-updateFromSpreadsheet">
        <b-card class="mb-4">
          <b-row class="my-1 align-items-end">
            <b-col lg="5">
              <label style="color: #c25052" v-if="!currentCompany"
                >First select the company and then upload the model</label
              >
              <label v-if="currentCompany"
                >Select a file to upload (.xlsx)</label
              >
              <b-form-file
                :disabled="!currentCompany"
                v-model="file"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                accept=".xlsx, .XLSX"
              ></b-form-file>
              <b-icon
                v-if="loadingFile"
                class="loading-file"
                icon="arrow-clockwise"
                animation="spin"
                font-scale="2"
              ></b-icon>
            </b-col>
            <b-col lg="7">
              <b-button v-if="isLoading" variant="primary" disabled>
                <b-spinner small type="grow"></b-spinner>
                Loading...
              </b-button>
              <b-button
                v-else
                variant="primary"
                :disabled="file == null"
                @click="uploadItem"
                >Upload</b-button
              >
              <b-button
                class="ml-2 float-right"
                variant="outline-primary"
                download
                :disabled="!currentCompany"
                @click="downloadKpiModel"
              >
                <b-icon icon="file-earmark-spreadsheet" class="mr-2"></b-icon>
                Download model
              </b-button>
            </b-col>
          </b-row>
          <b-row class="justify-content-end">
            <b-col cols="auto">
              <p
                v-if="currentCompany == null"
                style="font-size: 0.7rem; color: #c25052"
              >
                Select the company to download the model
              </p>
            </b-col>
          </b-row>
        </b-card>
      </b-collapse>
    </b-card>

    <b-card class="mb-4">
      <h4
        @click="updateFromAPI_show = !updateFromAPI_show"
        v-b-toggle.collapse-updateFromAPI
      >
        Update from API

        <b-icon-chevron-down v-if="updateFromAPI_show"></b-icon-chevron-down>
        <b-icon-chevron-up v-else></b-icon-chevron-up>
      </h4>

      <b-collapse id="collapse-updateFromAPI">
        <b-card class="mb-a"
          >Lorem ipsum, dolor sit amet consectetur adipisicing elit. Cum atque
          iure assumenda odit officiis doloremque aut esse, amet suscipit
          praesentium quibusdam facere ipsum corrupti beatae. Vitae deserunt
          adipisci laudantium distinctio.
          <br />
          <br />
          <b-card style="width: 50%"
            ><code
              >Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Cupiditate architecto explicabo inventore aliquid velit ad quis
              ullam aut ab similique repellendus iusto, vel voluptatem sapiente
              temporibus, enim, atque dolor iste.</code
            ></b-card
          >
          <br />
          <br />
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid
          debitis, commodi expedita eum quae non voluptas, voluptate unde
          distinctio libero voluptatum nobis rem, consequatur sunt labore amet
          cum numquam Lorem ipsum dolor sit amet consectetur adipisicing elit.
          Sint excepturi recusandae quibusdam saepe ipsam, laboriosam,
          aspernatur magni neque accusamus doloremque corporis iusto rem et iure
          magnam suscipit hic animi voluptatem?
        </b-card>
      </b-collapse>
    </b-card>

    <b-card class="mb-4">
      <h4
        @click="updateConfReminder_show = !updateConfReminder_show"
        v-b-toggle.collapse-reminder
      >
        Update reminder
        <b-icon-chevron-down
          v-if="updateConfReminder_show"
        ></b-icon-chevron-down>
        <b-icon-chevron-up v-else></b-icon-chevron-up>
      </h4>
      <b-collapse id="collapse-reminder">
        <b-card class="mt-2">
          <reminder />
        </b-card>
      </b-collapse>
    </b-card>

    <h3 class="pt-4">Kpis Update History</h3>
    <!-- <b-card class="mb-4">
      <h2 class="mb-3">Campaign: Prova</h2>
      <b-row class="my-1">
        <b-col lg="3">
          <b-input-group class="mb-2">
            <b-input-group-prepend is-text>
              <b-icon icon="search"></b-icon>
            </b-input-group-prepend>
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Search"
            ></b-form-input>
          </b-input-group>
        </b-col>
        <b-col lg="3">
          <b-col>
            <b-form-select
              v-model="roleTypeListSelected"
              :options="computedRoleTypeList"
            ></b-form-select>
          </b-col>
        </b-col>
        <b-col lg="2" class="ml-auto">
          <b-col>
            <b-form-select
              v-model="perPage"
              :options="perPageTypeList"
            ></b-form-select>
          </b-col>
        </b-col>
      </b-row>
    </b-card> -->
    <b-overlay :show="items == null" rounded="sm" class="overlay-placeholder">
      <b-card>
        <b-table
          hover
          :items="items"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filter"
        >
          <template #cell(Source)="row">
            <b-icon-file-earmark-excel
              class="ml-3"
              v-if="row.item.Source == 'File'"
            ></b-icon-file-earmark-excel>
            <b-icon-grid v-else class="ml-3"></b-icon-grid>
          </template>

          <template #cell(download)="row">
            <b-button
              variant="primary"
              :href="getDownloadAddress(row.item)"
              target="_blank"
              >Download</b-button
            >
          </template>
          <template #cell(uploadOk)="row">
            <b-icon-check-circle
              class="ml-4"
              style="color: green"
              v-if="row.item.uploadOk == true"
            >
            </b-icon-check-circle>
            <b-icon-x-circle
              class="ml-4"
              style="color: red"
              v-else
            ></b-icon-x-circle>
          </template>

          <template #cell(nome)="row">
            {{ row.item.nome.split("_")[1] }}
          </template>
          <!-- <template #cell(goal)="row">
            <b-input v-model="row.item.goal" />
          </template>
          <template #cell(new_value)="row">
            <b-input v-model="row.item.new_value" />
          </template> -->
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="items.length"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="mt-4 mb-1"
        ></b-pagination>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import AxiosService from "../axiosServices/AxiosService";
import { mapGetters } from "vuex";
import { SET_CURRENT_COMPANY } from "../store/actions/config";
import Reminder from "./Reminder.vue";

export default {
  components: {
    Reminder,
  },
  data() {
    return {
      mail_options_selected: null,
      alternative_mail_selected: null,
      mail_options: [],
      alternative_mail_options: [],
      default_mail: "",
      kpi_frequency: null,
      kpi_frequency_options: [
        { value: null, text: "Select frequency" },
        { value: 0, text: "Every day" },
        { value: 1, text: "Every week" },
        { value: 2, text: "Every month" },
        { value: 3, text: "Never" },
      ],
      updateConfReminder_show: true,
      updateFromSpreadsheet_show: true,
      updateFromAPI_show: true,
      currentCompany: null,
      companiesList: [{ value: null, text: "Select company" }],
      items: [],
      fields: [
        { key: "Source", label: "Source" },
        { key: "nome", label: "Document / Plugin" },
        { key: "dataCariamento", label: "Date" },
        { key: "uploadOk", label: "Uploaded" },
        { key: "download", label: "Download" },
      ],
      currentPage: 1,
      perPage: 10,
      perPageTypeList: [10, 25, 50, 100, 500],

      filter: "",
      advancedFilter_visible: false,
      currentItem: null,
      tmpItem: {
        id: 0,
        nome: "",
        cognome: "",
        email: "",
        telefono: "",
        avatar_url: "",
        companies: [],
        role: 0,
        area: "",
        department: "",
        cluster: "",
        attivo: true,
      },
      showDeleteModal: false,

      role_types: [],

      //Advanced Filter Options
      companyList: [],
      companyListSelected: null,

      roleTypeList: [],
      roleTypeListSelected: null,

      file: null,
      isLoading: false,
      loadingFile: false,
      idCampaign: this.$route.params.campaignId,
    };
  },
  service: null,
  campaignService: null,
  created() {
    this.campaignService = new AxiosService("Campaign");
    this.service = new AxiosService("UserBO");
  },
  async mounted() {
    this.service.readCustomEndpoint("Company").then((data) => {
      let array = data.map((item) => {
        return { value: item.id, text: item.ragione_sociale };
      });
      this.companiesList.push(...array);
    });
    this.default_mail = this.loggedUser.email;
    this.mail_options.push({
      value: 0,
      text: "To the email address: " + this.default_mail,
    });
    this.currentCompany = this.$store.getters.currentCompany;
    this.getMail();
  },
  methods: {
    getMail() {
      this.mail_options.splice(1);
      if (this.currentCompany) {
        this.campaignService
          .getOptions("BackofficeMail/" + this.currentCompany)
          .then((res) => {
            res.forEach((x) => {
              if (x.text != this.default_mail)
                this.alternative_mail_options.push(x.text);
            });
            if (this.alternative_mail_options.length) {
              this.mail_options.push({
                value: 1,
                text: "Other address",
              });
              this.alternative_mail_selected = this.alternative_mail_options[0];
            }
            this.set_mail_options_selected();
          });
      }
    },
    set_mail_options_selected() {
      this.mail_options_selected = 0;
      if (this.campaign) {
        if (this.alternative_mail_options.includes(this.campaign.kpi_mail)) {
          this.mail_options_selected = 1;
          this.alternative_mail_selected = this.campaign.kpi_mail;
        }
      }
    },
    getUploadHistory() {
      this.items.splice(0);
      this.service
        .readCustomEndpoint(
          "CampaignManager/GetKpiFiles/" + this.currentCompany
        )
        .then((res) => {
          console.log(
            "🚀 ~ file: UpdateKpis.vue ~ line 326 ~ .then ~ res",
            res
          );
          res.forEach((element) => {
            element.dataCariamento =
              element.dataCariamento.slice(8, 10) +
              "-" +
              element.dataCariamento.slice(5, 8) +
              element.dataCariamento.slice(0, 4);
            element.nome = element.filepath;
            this.items.push(element);
          });
        });
      this.getMail();
    },
    downloadKpiModel() {
      this.service
        .readCustomEndpoint("KpiManager/CreateKpiModels/" + this.currentCompany)
        .then((res) => {
          var fileURL = this.$store.getters.base_address + res;

          window.location.assign(fileURL);
        });
    },
    //     getCompanyKpi() {
    //       this.companyKpisList.splice(0);
    //       this.service
    //         .readCustomEndpoint("GlobalKpi?idCompany=" + this.currentCompany)
    //         .then((res) => {
    //           this.companyKpisList = res;
    //           this.tree = this.buildTree(this.companyKpisList);
    //         });
    //       this.service.readCustomEndpoint("SalesChat/GetProducts").then((res) => {
    //         res.forEach((element) => {
    //           element.value = element.id;
    //           element.text = element.nome;
    //           this.productsOptions.push(element);
    //         });
    //       });
    //     },
    resetData() {
      this.currentItem = null;
      this.tmpItem = {
        id: 0,
        nome: "",
        cognome: "",
        email: "",
        telefono: "",
        avatar_url: "",
        companies: [],
        role: 0,
        area: "",
        department: "",
        cluster: "",
        attivo: true,
      };
    },
    saveItem() {
      let item = { ...this.tmpItem };

      if (item.id) {
        this.service.update(item).then((response) => {
          if (response.id == item.id) {
            this.tmpItem = JSON.parse(JSON.stringify(item));
            this.$root.$emit("bv::toggle::collapse", "sidebar-backoffice");
            this.$successToast();
          } else {
            this.$errorToast();
            return false;
          }
        });
      } else {
        this.service.create(item).then((response) => {
          if (response) {
            item.id = response.id;
            this.items.push(item);
            this.$root.$emit("bv::toggle::collapse", "sidebar-backoffice");
            this.$successToast();
          } else {
            this.$errorToast();
            return false;
          }
        });
      }
    },
    deleteItem(id) {
      this.service.delete(id).then((response) => {
        if (response) {
          this.items = this.items.filter(
            (currentItem) => id !== currentItem.id
          );
          this.$successToast();
        } else {
          this.$errorToast();
          return false;
        }
      });
    },
    async uploadItem() {
      let fileUrl = "";
      this.isLoading = true;
      this.loadingFile = true;
      if (this.file) {
        if (this.file.size > 3 * 1024 * 1024) {
          alert("I file non possono superare il massimo di 3MB");
          return;
        }
        const formData = new FormData();
        formData.append("file", this.file);
        let fileName = await this.service
          .uploadFile(formData)
          .then((res) => res.data)
          .finally(() => {
            this.loadingFile = false;
          });
        if (fileName) {
          fileUrl = "/Drive/" + fileName;
        }
      }

      if (fileUrl) {
        const fileUrlObject = { filePath: fileUrl };
        this.service
          .postCustomEndpoint(
            "CampaignManager/UpdateKpiByFile",
            this.currentCompany,
            fileUrlObject
          )
          .then(() => {
            this.file = null;
            this.$successToast();
          })
          .catch(() => {
            this.$errorToast();
            return false;
          });
      } else {
        this.$errorToast();
        return false;
      }
      this.isLoading = false;
    },
    getDownloadAddress(item) {
      return this.$baseAddress + "/" + item.filepath;
    },
  },
  computed: {
    ...mapGetters(["loggedUser"]),
    kpi_mail() {
      if (this.mail_options_selected == 0) {
        return this.default_mail;
      } else {
        return this.alternative_mail_selected;
      }
    },
    currentCompanyFromStore() {
      return this.$store.getters.currentCompany;
    },
  },
  watch: {
    filter: {
      handler() {
        this.currentPage = 1;
      },
    },
    currentCompany: {
      handler() {
        this.$store.dispatch(SET_CURRENT_COMPANY, this.currentCompany);
        this.getUploadHistory();
      },
    },
    currentCompanyFromStore: {
      handler() {
        this.currentCompany = this.currentCompanyFromStore;
      },
    },
  },
};
</script>
